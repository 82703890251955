@import 'utilities/variables'

//RESET EVERYTHING
*
    margin: 0
    padding: 0
    outline: 0 none
    box-sizing: border-box

    ::-webkit-scrollbar
        width: 10px

    ::-webkit-scrollbar-track
        background: transparent

    ::-webkit-scrollbar-thumb
        background: $gray4

    ::-webkit-scrollbar-thumb:hover
        background: $gray3

:root
    --gap: 20px
    --columnas: var(--col)
    --col: 1fr
    --min-col: 100px

.green
    background: $green
.green2
    background: $green2
.black
    background: $black
.gray
    background: $gray1
.gray2
    background: $gray2
.gray3
    background: $gray3
.gray4
    background: $gray4
.gray5
    background: $gray5
.gray6
    background: $gray6
.white
    background: #fff
.orange
    background: $orange

.c-white
    color: #fff !important  
.c-green
    color: $green !important  
.c-green2
    color: $green2 !important      
.c-gray0
    color: $gray0 !important    
.c-gray1
    color: $gray1 !important
.c-gray2
    color: $gray2 !important
.c-black1
    color: $black1 !important  

$font1: 'Gotham Narrow',sans-serif
$font2: 'Inter',sans-serif

.typo
    font-family: $font1
.typo2
    font-family: $font2

//SCROLLBARS
.scroll-y::-webkit-scrollbar
    width: 16px !important


.scroll-y::-webkit-scrollbar-thumb
    background: #f6f6f6!important
    border-radius: 100vh!important
    background-clip: padding-box!important
    box-shadow: inset 0 0 15px 15px #eeeff2!important
    border: 4px solid transparent!important

.scroll-y::-webkit-scrollbar-track
    background: #fff!important
    border: 3px solid transparent!important

html
body
#root
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    color: $black
    line-height: 100%

a
    color: $gray1
    text-decoration: none
    &:active,
    &:hover
        color: $gray0

a, strong, b
    font-weight: 600

button, .button
    font-size: 16px
    font-weight: 500
    font-family: $font2
    line-height: 120%
    height: 43px

p, label, li, input, textarea, span, small
    font-family: $font2
    line-height: 120%
    font-weight: 400
    color: $gray1
    &:hover
        color: $gray0

h1,h2,h3,h4,h5,h6
    color: $black
    text-transform: none
    line-height: 100%
    letter-spacing: -1px
    &:first-of-type
        margin-top: 0

h1, .h1
    font-size: 44px
    margin: 20px 0
    //color: $gray
    font-weight: 900
    font-family: $font1
h2, .h2
    font-size: 36px
    margin: 20px 0
    font-family: $font1

h3, .h3
    font-size: 28px
    margin: 15px 0
    font-weight: 600
    font-family: $font2
    color: $gray2
h4, .h4
    font-size: 18px
    font-weight: 500
    letter-spacing: 0.5px
    text-transform: uppercase
    margin: 10px 0
    font-family: $font2
h5, .h5
    font-size: 16px
    font-weight: 600
    //text-transform: uppercase
    margin: 10px 0
    font-family: $font2
    letter-spacing: 0

p
    padding-bottom: 15px
    &:last-of-type
        padding-bottom: 0

//LAYOUT MODES
//FIXED FULL SCREEN FOR MOBILE COMPATIBILITY
.layout-fixed
    position: fixed
    inset: 0
    width: 100%
    height: 100%

.t0
    top: 0
.r0
    right: 0
.l0
    left: 0
.b0
    bottom: 0
.z99
    z-index: 99

.decoration-none
    text-decoration: none !important

// ABSOLUTE Y MEDIA QUERY XL, LG, MD, SM, XS
.absolute
    position: absolute
.absolute-xl
    @include xl()
        position: absolute
.absolute-lg
    @include lg()
        position: absolute
.absolute-md
    @include md()
        position: absolute
.absolute-sm
    @include sm()
        position: absolute
.absolute-xs
    @include xs()
        position: absolute

// RELATIVE Y MEDIA QUERY XL, LG, MD, SM, XS
.relative
    position: relative
.relative-xl
    @include xl()
        position: relative
.relative-lg
    @include lg()
        position: relative
.relative-md
    @include md()
        position: relative
.relative-sm
    @include sm()
        position: relative
.relative-xs
    @include xs()
        position: relative

// FIXED Y MEDIA QUERY XL, LG, MD, SM, XS
.fixed
    position: fixed
.fixed-xl
    @include xl()
        position: fixed
.fixed-lg
    @include lg()
        position: fixed
.fixed-md
    @include md()
        position: fixed
.fixed-sm
    @include sm()
        position: fixed
.fixed-xs
    @include xs()
        position: fixed

// FLEX Y MEDIA QUERY XL, LG, MD, SM, XS
.flex
    display: flex !important
.flex-xl
    @include xl()
        display: flex !important
.flex-lg
    @include lg()
        display: flex !important
.flex-md
    @include md()
        display: flex !important
.flex-sm
    @include sm()
        display: flex !important
.flex-xs
    @include xs()
        display: flex !important

// BLOCK Y MEDIA QUERY XL, LG, MD, SM, XS
.block
    display: block !important
.block-xl
    @include xl()
        display: block !important
.block-lg
    @include lg()
        display: block !important
.block-md
    @include md()
        display: block !important
.block-sm
    @include sm()
        display: block !important
.block-xs
    @include xs()
        display: block !important

// NONE Y MEDIA QUERY XL, LG, MD, SM, XS
.none
    display: none !important
.none-xl
    @include xl()
        display: none !important
.none-lg
    @include lg()
        display: none !important
.none-md
    @include md()
        display: none !important
.none-sm
    @include sm()
        display: none !important
.none-xs
    @include xs()
        display: none !important

// ROW Y MEDIA QUERY XL, LG, MD, SM, XS
.row
    flex-direction: row !important
.row-xl
    @include xl()
        flex-direction: row !important
.row-lg
    @include lg()
        flex-direction: row !important
.row-md
    @include md()
        flex-direction: row !important
.row-sm
    @include sm()
        flex-direction: row !important
.row-xs
    @include xs()
        flex-direction: row !important

// COL Y MEDIA QUERY XL, LG, MD, SM, XS
.col
    flex-direction: column !important
.col-xl
    @include xl()
        flex-direction: column !important
.col-lg
    @include lg()
        flex-direction: column !important
.col-md
    @include md()
        flex-direction: column !important
.col-sm
    @include sm()
        flex-direction: column !important
.col-xs
    @include xs()
        flex-direction: column !important

// ALING Y MEDIA QUERY XL, LG, MD, SM, XS
.align-s
    align-items: flex-start !important
.align-xl-s
    @include xl()
        align-items: flex-start !important
.align-lg-s
    @include lg()
        align-items: flex-start !important
.align-md-s
    @include md()
        align-items: flex-start !important
.align-sm-s
    @include sm()
        align-items: flex-start !important
.align-xs-s
    @include xs()
        align-items: flex-start !important

// ALIGN Y MEDIA QUERY XL, LG, MD, SM, XS
.align-c
    align-items: center !important
.align-xl-c
    @include xl()
        align-items: center !important
.align-lg-c
    @include lg()
        align-items: center !important
.align-md-c
    @include md()
        align-items: center !important
.align-sm-c
    @include sm()
        align-items: center !important
.align-xs-c
    @include xs()
        align-items: center !important

// ALIGN Y MEDIA QUERY XL, LG, MD, SM, XS
.align-e
    align-items: flex-end !important
.align-xl-e
    @include xl()
        align-items: flex-end !important
.align-lg-e
    @include lg()
        align-items: flex-end !important
.align-md-e
    @include md()
        align-items: flex-end !important
.align-sm-e
    @include sm()
        align-items: flex-end !important
.align-xs-e
    @include xs()
        align-items: flex-end !important

// JUSTIFY Y MEDIA QUERY XL, LG, MD, SM, XS
.just-s
    justify-content: flex-start !important
.just-xl-s
    @include xl()
        justify-content: flex-start !important
.just-lg-s
    @include lg()
        justify-content: flex-start !important
.just-md-s
    @include md()
        justify-content: flex-start !important
.just-sm-s
    @include sm()
        justify-content: flex-start !important
.just-xs-s
    @include xs()
        justify-content: flex-start !important

// JUSTIFY Y MEDIA QUERY XL, LG, MD, SM, XS
.just-c
    justify-content: center !important
.just-xl-c
    @include xl()
        justify-content: center !important
.just-lg-c
    @include lg()
        justify-content: center !important
.just-md-c
    @include md()
        justify-content: center !important
.just-sm-c
    @include sm()
        justify-content: center !important
.just-xs-c
    @include xs()
        justify-content: center !important

// JUSTIFY Y MEDIA QUERY XL, LG, MD, SM, XS
.just-e
    justify-content: end !important
.just-xl-e
    @include xl()
        justify-content: end !important
.just-lg-e
    @include lg()
        justify-content: end !important
.just-md-e
    @include md()
        justify-content: end !important
.just-sm-e
    @include sm()
        justify-content: end !important
.just-xs-e
    @include xs()
        justify-content: end !important

// JUSTIFY Y MEDIA QUERY XL, LG, MD, SM, XS
.just-sb
    justify-content: space-between !important
.just-xl-sb
    @include xl()
        justify-content: space-between !important
.just-lg-sb
    @include lg()
        justify-content: space-between !important
.just-md-sb
    @include md()
        justify-content: space-between !important
.just-sm-sb
    @include sm()
        justify-content: space-between !important
.just-xs-sb
    @include xs()
        justify-content: space-between !important

// JUSTIFY Y MEDIA QUERY XL, LG, MD, SM, XS
.just-sa
    justify-content: space-around !important
.just-xl-sa
    @include xl()
        justify-content: space-around !important
.just-lg-sa
    @include lg()
        justify-content: space-around !important
.just-md-sa
    @include md()
        justify-content: space-around !important
.just-sm-sa
    @include sm()
        justify-content: space-around !important
.just-xs-sa
    @include xs()
        justify-content: space-around !important

.g40
    gap: 40px
.g30
    gap: 30px
.g20
    gap: 20px
.g15
    gap: 15px
.g10
    gap: 10px
.g5
    gap: 5px

//WIDTHS

// WIDTHS Y MEDIA QUERY XL, LG, MD, SM, XS
.w100
    width: 100%
.w100-xl
    @include xl()
        width: 100%
.w100-lg
    @include lg()
        width: 100%
.w100-md
    @include md()
        width: 100%
.w100-sm
    @include sm()
        width: 100%
.w100-xs
    @include xs()
        width: 100%

// HEIGHT Y MEDIA QUERY XL, LG, MD, SM, XS
.h100
    height: 100%
.h100-xl
    @include xl()
        height: 100%
.h100-lg
    @include lg()
        height: 100%
.h100-md
    @include md()
        height: 100%
.h100-sm
    @include sm()
        height: 100%
.h100-xs
    @include xs()
        height: 100%

.w50
    width: 50%
.w33
    width: 33.33%
.w25
    width: 25%
.w10
    width: 10%
.wauto
    width: auto
.wx120
  width: 120px !important

// //PADDINGS
// .p40
//     padding: 40px
// .p20
//     padding: 20px
// .p10
//     padding: 10px
// .p40-20
//     padding: 40px 20px
// .p20-40
//     padding: 20px 40px
// .p5
//     padding: 5px
// .p0
//     padding: 0px

// .pb0
//     padding-bottom: 0px  

// .pb5
//     padding-bottom: 5px 

// .pb10
//     padding-bottom: 10px  

// .pb15
//     padding-bottom: 15px  

// .pb20
//     padding-bottom: 20px  

.fw400
    font-weight: 400
.fw500
    font-weight: 500
.fw600
    font-weight: 600
.fw700
    font-weight: 700
.fw800
    font-weight: 800

// FONT SIZE Y MEDIA QUERY XL, LG, MD, SM, XS
.fs0
  font-size: 0 !important
.fs0-xl
    @include xl()
        font-size: 0 !important
.fs0-lg
    @include lg()
        font-size: 0 !important
.fs0-md
    @include md()
        font-size: 0 !important
.fs0-sm
    @include sm()
        font-size: 0 !important
.fs0-xs
    @include xs()
        font-size: 0 !important


.fs8
  font-size: 8px !important

.fs9
  font-size: 9px !important

.fs10
  font-size: 10px !important

.fs11
  font-size: 11px !important

.fs12
  font-size: 12px !important

.fs13
  font-size: 13px !important

.fs14
  font-size: 14px !important

.fs15
  font-size: 15px !important

.fs16
  font-size: 16px !important

.fs17
  font-size: 17px !important

.fs18
  font-size: 18px !important

.fs19
  font-size: 19px !important

.fs20
  font-size: 20px !important

.fs22
  font-size: 22px !important

.fs24
  font-size: 24px !important

.fs26
  font-size: 26px !important

.fs28
  font-size: 28px !important

.fs30
  font-size: 30px !important

.fs32
  font-size: 32px !important

.fs36
  font-size: 36px !important

.fs40
  font-size: 40px !important

.fs48
  font-size: 48px !important

.fs60
  font-size: 60px !important

// .pl0
//     padding-left: 0px !important
// .pl5
//     padding-left: 5px    
// .pl30
//     padding-left: 30px !important

// .pr0
//     padding-right: 0px !important    
//MARGINS
// .m20
//     margin: 20px
// .m10
//     margin: 10px
// .m5
//     margin: 5px

// .mt5
//     margin-top: 5px
// .mt10
//     margin-top: 10px
// .mt15
//     margin-top: 15px
// .mt20
//     margin-top: 20px
// .mt40
//     margin-top: 40px

// .mr0 
//     margin-right: 0px !important
// .mr5
//     margin-right: 5px
// .mr10
//     margin-right: 10px
// .mr15
//     margin-right: 15px
// .mr20
//     margin-right: 20px
// .mr40
//     margin-right: 40px


// .mb0
//     margin-bottom: 0px
// .mb5
//     margin-bottom: 5px
// .mb10
//     margin-bottom: 10px
// .mb15
//     margin-bottom: 15px
// .mb20
//     margin-bottom: 20px
// .mb40
//     margin-bottom: 40px

// .ml5
//     margin-left: 5px
// .ml10
//     margin-left: 10px
// .ml15
//     margin-left: 15px
// .ml20
//     margin-left: 20px
// .ml40
//     margin-left: 40px
// .ml-20
//     margin-left: -20px !important


//DISABLERS
.ls-no
    list-style: none

//LAYOUT DERIVED CLASSES
.scroll-y
    overflow-y: scroll

//WRAPPER ELEMENTS
.wrapper
    max-width: 1280px
    width: 100%
    margin: 0 auto

// CURSORES
.cursor-pointer
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none


.pointer-event-none
    pointer-events: none 

//LISTS
ul
    &.default
        display: flex
        flex-direction: column
        list-style: none
        gap: 5px 0
        li
            display: block
            line-height: 120%
            // gap: 0 5px
            // flex-direction: row

            // flex-wrap: wrap
            // justify-content: flex-start
            // align-items: flex-start
            &:before
                content: ""
                width: 15px
                height: 15px
                background: #000
                border-radius: 100%
                display: inline-block
                margin: 0 5px -2px 0
            a
                display: inline-block
                width: auto

            &.parent
                display: block
                &:before
                    margin-right: 5px

            ul
                padding-top: 0.5rem
                padding-left: 1.2rem
                padding-bottom: 0.25rem
                width: 100%
                li
                    &:before
                        background: #fff
                        border: 2px solid #000
                        width: 11px
                        height: 11px
                    ul
                        li
                            &:before
                                background: #999
                                border: 2px solid #999
                            ul
                                li
                                    &:before
                                        border-radius: 0
                                        width: 15px
                                        height: 15px
                                        border: none
                                        background: #000

                                    ul
                                        li
                                            &:before
                                                background: #fff
                                                border-radius: 0
                                                border: 2px solid #000
                                                width: 11px
                                                height: 11px
                                            ul
                                                li
                                                    &:before
                                                        background: #999
                                                        border: 2px solid #999
                                                        border-radius: 0
                                                        width: 11px
                                                        height: 11px

    &.stoplight
        li
            &.ok
                &:before
                    background: $green
            &.warning
                &:before
                    background: #FFD700
            &.error
                &:before
                    background: #F11818

    &.hierarchy
        position: relative
        padding-left: 20px
        list-style: none
        &:before
            content: ""
            width: 2px
            background: #ccc
            display: block
            position: absolute
            left: 0
            top: 10px
            height: calc(100% - 18px)

        li
            position: relative
            &:before
                content: ""
                height: 2px
                width: 15px
                background: #ccc
                display: block
                position: absolute
                left: -20px
                top: 10px
                border-radius: 0

        ul
            position: relative
            padding-left: 20px
            list-style: none
            &:before
                content: ""
                width: 2px
                background: #ccc
                display: block
                position: absolute
                left: 0
                top: 10px
                height: calc(100% - 18px)
            &:after
                content: ""
                width: 2px
                background: #ccc
                display: block
                position: absolute
                left: 0
                top: 0px
                height: 10px


ol
    padding-left: 1.2rem
    list-style: none
    counter-reset: section

    li

        &:before
            counter-increment: section
            content: counters(section, ".") " "

//BOXES / CARDS
.box
    border: 1px solid rgba(0,0,0,0.15)
    margin-bottom: 20px
    padding: 20px
    //width: auto
    width: 100%
    //box-shadow: 0 2px 0 2px 0 rgba(0,0,0,0.15)
    &.w-adjust
        width: max-content
        max-width: 100%
.card
    border: 1px solid rgba(0,0,0,0.15)
    padding: 20px
    //margin-bottom: 20px
    border-radius: 5px
    display: block
    box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 15%)
    background: #fff

    &.int
        padding: 0
        > *
            padding: 20px



    &.rows
        display: flex
        flex-direction: row
        > *
            border-right: 1px solid rgba(0,0,0,0.15)
            padding-right: 20px
            margin-right: 20px
            &:last-of-type
                border-right: none
                margin-right: 0
                padding-right: 0
        &.int
            > *
                margin-right: 0
                &:last-of-type
                    padding-right: 20px
    &.cols
        display: flex
        flex-direction: column
        > *
            border-bottom: 1px solid rgba(0,0,0,0.15)
            padding-bottom: 20px
            margin-bottom: 20px
            &:last-of-type
                border-bottom: none
                margin-bottom: 0
                padding-bottom: 0
        &.int
            > *
                margin-bottom: 0
                &:last-of-type
                    padding-bottom: 20px


//BUTTONS
button,.button
    display: inline-block
    border: none
    background: transparent
    .button-wrapper
        padding: 10px 15px
        display: block
        background: $gray4
        color: $gray0
        border-radius: 3px
        border-bottom: 2px solid rgba(0,0,0,0.15)
    &:hover, &.hover
        .button-wrapper
            cursor: pointer
            background: $gray3
    &:active, &.active
        .button-wrapper
            //margin: 4px 0 2px
            transform: translateY(2px)
            padding: 6px 15px 8px
            border-top: 2px solid rgba(0,0,0,0.15)
            border-bottom-color: transparent


    &.important .button-wrapper
        background: $gray1
        color: #fff
        &:hover, &.hover
            background: $gray0

    &.primary .button-wrapper
        background: $green
        color: #fff
        &:hover, &.hover
            background: $green2
    &.caution .button-wrapper
        background: $orange
        color: #fff
        &:hover, &.hover
            background: $orange2

    &[disabled]
        pointer-events: none
        .button-wrapper
            border: 1px solid #dedddc
            color: #bdbbb9
            background-color: transparent

    &.loading
        .button-wrapper
            padding-left: 40px
            background: url(../assets/imgs/loading.png) no-repeat scroll 10px center #fff
            background-size: 20px auto

.height-btn
  height: auto  
  padding : 6px 20px  !important 

.bg-yellow
  .button-wrapper
    background: rgba(251, 207, 35)
    color : #fff
    &:hover
      background: rgb(214, 171, 0)
      color : #fff
.bg-blue
  .button-wrapper
    background: rgb(0, 116, 199)
    color : #fff
    &:hover
      background: rgb(1, 95, 162)
      color : #fff
.bg-green
  .button-wrapper
    background: rgb(76, 154, 48)
    color : #fff
    &:hover
      background: rgb(63, 133, 37)
      color : #fff   

//REUSABLE ELEMENTS OF LOGO NATURE
.logo
    display: flex
    align-items: center
    gap: 5px
    // width: 95px
    // height: 24px
    // background: url(../imgs/logo-full-green-horizontal.svg) no-repeat scroll center center transparent
    // background-size: contain
    font-size: 30px
    font-weight: bold
    line-height: 100%
    color: $black
    font-family: $font2
    span
        display: inline-block
        background: #71d44c
        color: #fff
        padding: 3px 5px
        font-weight: 500
        text-transform: uppercase
        font-size: 12px
        border-radius: 2px
        margin-top: 6px
        letter-spacing: 0.5px
        line-height: 100%

//CUSTOM
.nav
    border-bottom: 1px solid rgba(0,0,0,0.15)
    ul
        li
            a, span
                font-weight: 500

.main
    padding-top: 71px
    //height: calc(100% - 71px)
    min-height: 100%
    .wrapper
        height: 100%
.sidebar
    width: 250px
    border-right: 1px solid rgba(0,0,0,0.15)
    height: calc(100% - 71px)
    ul
        margin: 5px 0 20px

        li
            border-left: 2px solid transparent



            a
                width: 100%
                display: block
                font-family: $font2
                font-size: 14px
                font-weight: 400
            &:hover
                //background: $gray4
                border-left-color: $gray4
                a
                    color: $black
            &.selected
                //background: $gray3
                border-left: 2px solid $green
                a
                    color: $green
.content
    width: calc(100% - 250px)
    margin-left: 250px


    .section
        margin-bottom: 40px
        &:last-of-type
            margin-bottom: 0


//CUSTOMSç
.sampler
    margin: 20px 0
    padding: 20px
    border: 1px solid rgba(0,0,0,0.15)
.typo-list
    li
        border-bottom: 1px solid rgba(0,0,0,0.15)
        padding: 20px 0
        h1,h2,h3,h4,h5,h6
            margin: 0
        p
            padding: 0


//COMPLEX COMPONENTS: NAVBAR
.nav
    width: 100%
    border-bottom: 1px solid rgba(0, 0, 0, 0.15)
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    z-index: 99
    left: 0
    right: 0
    top: 0
    position: fixed
    background: #fff
    .wrapper
        width: 100%
        padding: 20px 40px
        display: flex
        justify-content: space-between
        align-items: center
        height: auto

        .icon
            cursor: pointer
            width: 30px
            height: 30px

            &:hover
                fill: $green

        .nav-menu
            display: flex

            .active
                color: $green

        .hamburger
            display: none

    @media screen
        @media (max-width: 800px)
            .wrapper
                .nav-menu
                    display: none !important
                .hamburger
                    display: flex

    &.on
        z-index: 100
        height: 100%

    .navbar-menu
        width: 100%
        height: 100%
        z-index: 9999
        padding: 0
        align-items: center
        justify-content: center
        padding: 20px 0

        ul
            list-style: none
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            gap: 20px

            li
                font-size: 18px
                font-weight: 500
                text-align: center

                .active
                    color: $green


.position-relative
    position: relative

.tag
    background-color: $white
    border: 1px solid $gray3
    font-size: 0.8rem
    font-weight: 600
    padding: 0.3rem
    text-transform: uppercase
    display: inline-block

    &.small
        font-size: 0.6rem
        padding: 0.3rem

    &.big
        font-size: 1rem
        padding: 0.5rem

    &.round
        border-radius: 5px


.listing
    list-style: none
    width: 100%
    .item-listing
        border-bottom: 1px solid $gray3
        padding: 0.4rem 0.3rem
        display: flex
        align-items: center
        justify-content: space-between

        &:hover
            background: $gray4

        &.item-link
            cursor: pointer

input
    border: 2px solid $gray3
    padding: 10px
    width: 100%
    font-size: 16px
    background: #f9f9f9
    &:hover
        border-color: $gray1
    &:active
        border-color: $green
    &:focus
        border-color: $green
        //background-color: #fff

    &.search
    &.number
    &.number-hidden-spinner
        -moz-appearance: textfield
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button
            -webkit-appearance: none
            margin: 0



    &.date
    &.time
    &.datetime-local
    &.input
        font-size: 16px
        color: $gray0

.password
    font-size: 16px
    width: 100%
    display: flex
    align-items: center
    position: relative

    input
        width: 100%
        font-size: 16px
        color: $gray0
        padding: 10px 40px 10px 10px

    svg
        display: block
        width: 20px
        height: 20px
        //margin-right: 10px
        cursor: pointer
        position: absolute
        right: 10px
        &.password-show

            path, circle
                fill: none
                stroke: #ccc
                stroke-width: 40px
            &:hover
                path, circle
                    stroke: $green

        &.password-hide
            path, circle
                fill: #ccc
                stroke: unset
            &:hover
                path, circle
                    fill: $green





.content-table
    width: 100%
    overflow: visible
    background-color: $white
    padding: 12px 6px

    .filters
        display: flex
        gap: 15px
        margin-bottom: 20px
        justify-content: end
        flex-wrap: wrap

        label
            margin-bottom: 5px

        input
            border: 1px solid $gray3
            border-radius: 3px
            padding: 4px
            font-size: 16px
            height: 100%

        .filter-box
            display: flex
            flex-direction: column

            .filter-label
                font-size: 12px
                font-weight: bold
                text-transform: uppercase
                color: $gray2
                letter-spacing: 1px

    .table
        overflow-x: auto
        width: 100%
        font-family: $font2
        display: block

        .table-row
            

            &.table-header
                font-weight: 600

                // display: block
                border-bottom: 2px solid $gray3

                td
                    text-align: left    
                    padding: 6px 0
                    white-space: nowrap

                    &.text-left
                        text-align: left

                    &.text-right
                        text-align: right

                    &.text-center
                        text-align: center

                    &.text-justify
                        text-align: justify

                    &:first-child
                        padding: 0

                    .td-content
                        display: flex
                        align-items: center
                        gap: 0 10px

                        .icon
                            cursor: pointer
                            width: 20px
                            height: 20px
                            margin-left: 5px
                            -webkit-transition: all 0.5s ease-out
                            -moz-transition: all 0.5s ease
                            -ms-transition: all 0.5s ease

                            &:up
                                -webkit-transform: rotate(-180deg)
                                -moz-transform: rotate(-180deg)
                                -ms-transform: rotate(-180deg)
                                transform: rotate(-180deg)

                        .table-arrow
                            height: 100%
                            width: 100%
                            display: flex
                            flex-direction: column

                            svg
                                height: 10px
                                width: 10px
                                fill: #ddd

                                &:last-child
                                    transform: rotate(180deg)

                                &:hover, &.on
                                    fill: #999

            align-items: center
            width: fit-content
            min-width: 100%
            

            border-bottom: 2px solid $gray3

            td
                text-align: left
                padding: 6px 0 
                white-space: nowrap

                &.text-left
                    text-align: left

                &.text-right
                    text-align: right

                &.text-center
                    text-align: center

                &.text-justify
                    text-align: justify

                &:first-child
                    padding: 0

                &.btn
                    display: flex
                    gap: 10px
                    align-items: center

                &.icon

                    svg
                        width: 24px
                        height: 24px

                .icon
                    display: none
                    cursor: pointer
                    fill: $green
                    width: 20px
                    height: 20px
                    margin: 0 10px 0 0

                    &:hover
                        fill: $green2

                    @media screen
                        @media (max-width: 900px)
                            display: block


                


                &.empty-state
                    text-align: center
                    padding: 20px 0

        tr
            display: grid
            width: 100%
            grid-template-columns: var(--columnas)
            gap: var(--gap)


            td
                padding: 10px 0
                overflow: hidden

                &.collapsed
                    display: none


        .table-mobile
            background-color: $gray6

            .container
                display: flex
                align-items: center
                justify-content: space-between
                padding: 10px
                border-bottom: 1px solid rgba(0, 0, 0, 0.15)

                span
                    width: 50%
                    white-space: break-spaces

                    &.btn
                        display: flex
                        flex-direction: column
                        gap: 10px
                        align-items: flex-start

                    &.icon

                        svg
                            width: 24px
                            height: 24px

    .table-empty
        text-align: center
        font-size: 20px
        padding: 20px 0
        font-weight: 600


    .pagination
        display: flex
        align-items: center
        margin-top: 20px
        justify-content: end
        gap: 15px

        .pag

            ul
                list-style: none
                display: flex
                gap: 8px
                align-items: center
                padding: 0
                margin: 0

                li
                    padding: 0 7px 2px
                    background-color: $white
                    color: $black
                    border: 1px solid rgba(0, 0, 0, 0.15)
                    border-radius: 5px
                    cursor: pointer

                    &:hover, &.active
                        background-color: $green
                        color: $white
                        border: 1px solid $green

        .next, .prev
            display: flex
            align-items: center
            cursor: pointer

            &.disabled
                cursor: none
                pointer-events: none
                opacity: .2

            &:hover
                svg
                    fill: $green

.sidenav
  height: 100%
  width: 0
  position: fixed
  z-index: 100
  top: 0
  right: 0
  background-color: $white
  overflow-x: hidden
  transition: 0.5s
  border: 1px solid $gray3
  box-shadow: 0 0 10px rgba(0,0,0,0.15)

.sidenav-top
        display: flex
        justify-content: space-between
        align-items: center

        padding: 20px 25px 20px 25px

        h4
            margin: 0
            font-size: 1.2rem


        .close
            font-size: 34px
            margin-left: 0px
            margin-right: 0px
            color: #818181
            &:hover
                color: #000
                text-decoration: none
                cursor: pointer

.sidenav-contenido
        padding: 0px 25px 25px 25px
        text-decoration: none
        //font-size: 22px
        color: #818181
        display: block
        transition: 0.3s
        overflow-y: scroll
.sidenav-bottom
        position: absolute
        display: flex
        justify-content: space-evenly
        align-items: center
        bottom: 30px
        width: 100%
        color: #818181
        background-color: $white

.dropdown
    border: none
    background: $white
    border-radius: 3px
    border: 2px solid $gray3
    font-size: 1rem
    min-width: 200px
    height: 43px
    gap: 0 10px
    display: flex
    align-items: center
    justify-content: space-between
    position: relative

    .dropdown-collpsed
        display: flex
        gap: 10px
        align-items: center
        justify-content: space-between
        padding: 10px 15px
        width: 100%

    .dropdown-display
        color: #aaa

    &:hover
        cursor: pointer
        border: 2px solid #999
        .dropdown-display
            color: #777
        .arrow
            svg
                > *
                    fill: #999

    &:active, &.on
        border: 2px solid $green
        border-color: $green
        .arrow
            svg
                > *
                    fill: $green
    &.on
        .arrow
            transform: rotate(0deg)
        .dropdown-content
            display: block


.arrow
    transform: rotate(180deg)
    margin: 0
    border: none
    display: block
    width: 10px
    height: 10px
    svg
        display: block
        width: 100%
        height: 100%
        > *
            fill: #ddd

.dropdown-content
    display: none
    position: absolute
    background-color: $white
    min-width: 200px
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
    z-index: 2
    border: 2px solid $gray3
    border-radius: 3px
    margin-top: 10px
    max-height: 200px
    overflow-y: auto
    left: 0
    right: 0
    top: 29px
    margin-left: -2px

    .dropdown-item
        z-index: 100
        border-radius: 3px
        padding: 10px 15px 10px 15px
        font-size: 1rem
        &:hover
            background: $gray4
            cursor: pointer
        &.active
            background: $gray4
            cursor: pointer
            border: 1px solid $green
            border-radius: 3px
            padding: 10px 15px

.progress-container
    width: 100%
    display: flex
    align-items: center

    .linea-progress
        width: 100%
        height: 5px
        position: relative

        .back
            background-color: $gray3
            opacity: 0.4
            display: block
            height: 100%
            position: relative

        .progress
            background-color: $gray3
            display: block
            height: 100%
            position: absolute
            top: 0

            &.important
                background-color: $gray0

            &.primary
                background-color: $green

            &.caution
                background-color: $orange

            &.no-animation
                width: 100%

            &.animation
                -webkit-transition: all 0.5s ease-out
                -moz-transition: all 0.5s ease
                -ms-transition: all 0.5s ease

            &.animate
                animation-duration: 5s
                animation-name: progress
                animation-iteration-count: infinite

            @keyframes progress
                0%
                    width: 0%

                100%
                    width: 100%

        .round-10
            border-radius: 10px


    p
        white-space: nowrap
        font-size: 16px
        margin: 0 0 2px 0
        padding-bottom: 0


.breadcrumb
    font-family: $font2
    display: flex
    align-items: center
    gap: 10px
    margin-bottom: 20px

    .arrow
        transform: rotate(90deg)

    .breadcrumb-item
        //font-size: 16px
        font-weight: 500
        margin: 10px 0

        font-size: 14px
        color: $gray0
        &:hover
            cursor: pointer
            color: $green
        &.active
            color: $green


    .breadcrumb-icon
        width: 20px
        height: 20px
        margin: 0 10px 0 0

        &:hover
            fill: $green2

        @media screen
            @media (max-width: 900px)
                display: block

.switch
    position: relative
    display: inline-block
    width: 38px
    height: 22px

    input
        display: none

        &:checked + .slider
            background-color: $green

            &.blue
                background-color: $blue

            &::before
                -webkit-transform: translateX(16px)
                -ms-transform: translateX(16px)
                transform: translateX(16px)

    .slider
        position: absolute
        cursor: pointer
        top: 0
        left: 0
        right: 0
        bottom: 0
        -webkit-transition: .4s
        transition: .4s
        border-radius: 10px

        &::before
            position: absolute
            content: ""
            height: 16px
            width: 16px
            left: 3px
            bottom: 3px
            background-color: $white
            -webkit-transition: .4s
            transition: .4s
            border-radius: 50%

        &.default
            background-color: $gray3

.switch-code
    position: relative
    display: inline-block
    min-width: 190px
    height: 34px

    input
        display: none

        &:checked + .slider

            .after
                svg
                    fill: $blue

            .before
                svg
                    fill: $gray0

            &::before
                -webkit-transform: translateX(92px)
                -ms-transform: translateX(92px)
                transform: translateX(92px)

    .slider
        position: absolute
        cursor: pointer
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: $gray4
        -webkit-transition: .4s
        transition: .4s
        border-radius: 5px
        display: flex
        align-items: center
        // justify-content: space-around
        // gap: 15px

        &::before
            position: absolute
            content: ""
            height: 28px
            width: 92px
            left: 3px
            bottom: 3px
            background-color: $white
            -webkit-transition: .4s
            transition: .4s
            border-radius: 5px

        span
            position: relative
            display: flex
            gap: 5px
            text-transform: uppercase
            font-size: 12px
            font-weight: bold
            letter-spacing: 0px
            justify-content: flex-start
            padding: 0 10px
            align-items: center
        .before
            left: 0
            width: 50%

            svg
                height: 20px
                width: 20px
                fill: $blue

        .after
            right: 0
            width: 50%

            svg
                height: 20px
                width: 20px

.switch-text
    --width: 190px
    --height: 34px
    --background: #{$gray4}
    --background-checked: #{$white}

    position: relative
    display: inline-block
    min-width: var(--width)
    height: var(--height)

    input
        display: none

        &:checked + .slider

            .after
                svg
                    fill: $blue

            .before
                svg
                    fill: $gray0

            &::before
                -webkit-transform: translateX(calc( var(--width)/2 - 3px ))
                -ms-transform: translateX(calc( var(--width)/2 - 3px ))
                transform: translateX(calc( var(--width)/2 - 3px ))

    .slider
        position: absolute
        cursor: pointer
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: var(--background)
        -webkit-transition: .4s
        transition: .4s
        border-radius: 5px
        display: flex
        align-items: center

        &::before
            position: absolute
            content: ""
            height: calc( var(--height) - 6px )
            width: calc( var(--width)/2 - 3px )
            left: 3px
            bottom: 3px
            background-color: var(--background-checked)
            -webkit-transition: .4s
            transition: .4s
            border-radius: 5px

        span
            position: relative
            display: flex
            gap: 5px
            text-transform: uppercase
            font-size: 12px
            font-weight: bold
            letter-spacing: 0px
            justify-content: flex-start
            padding: 0 10px
            align-items: center
        .before
            left: 0
            width: 50%

        .after
            right: 0
            width: 50%


.code-content
    width: 100%
    overflow-x: auto
    position: relative

    pre
        code
            font-family: $font2
            color: #71d44c

            .diff .hljs-meta, .hljs-keyword, .hljs-template-tag, .hljs-type
                color: #bf35de

            .hljs-class .hljs-title, .hljs-title, .hljs-title.class_, .hljs-attr
                color: #00ecff

            .hljs-addition, .hljs-code, .hljs-string, .hljs-title.class_.inherited__
                color: #de8a35

            .hljs-link, .hljs-literal, .hljs-number, .hljs-symbol, .hljs-variable.constant_, .hljs-function
                color: #0028ff

            .hljs-built_in, .hljs-doctag, .hljs-keyword.hljs-atrule, .hljs-quote, .hljs-regexp
                color: #ff0303

            .hljs-params
                color: #ded635

            .hljs-tag, .hljs-name
                color: #35de98

            .language-xml
                color: #00ff28

            span
                pointer-events: none

    .copy
        position: absolute
        right: 5px
        top: 5px
        background: transparent
        padding: 5px 8px
        border:  1px solid $gray1
        border-radius: 5px
        cursor: pointer

        svg
            color: $gray1
            height: 20px
            width: 20px

        &:hover
            border:  1px solid $gray2

            svg
                color: $gray2


        &.active
            border:  1px solid $green

            svg
                color: $green



.control
    position: relative
    display: flex
    align-items: center
    gap: 10px
    border-bottom: 2px solid $gray3
    width: 100%

    .input
        width: 100%
        padding: 10px 40px 10px 15px
        border: none
        border-radius: 3px
        font-size: 1rem
        font-family: $font2
        color: $gray0
        background: $white


    .icon
        position: absolute
        width: 15px
        height: 15px
        fill: $gray2
        z-index: 4
        display: flex
        justify-content: center
        right: 15px
        svg
            > *
                fill: $gray2

.input-create
    position: relative
    display: flex
    align-items: center
    gap: 15px
    width: 100%
    &.bb
        border-bottom: 2px solid $gray3
    &.bt
        border-top: 2px solid $gray3
    .input
        width: calc(100% - 5px)
        padding: 10px 40px 10px 15px
        border: none
        border-radius: 3px
        font-size: 1rem
        font-family: $font2
        color: $gray0
        background: $white
    .icon
        background-color: $green
        position: absolute
        top: 0
        right: 0
        bottom: 0
        padding: 0 10px
        display: flex
        align-items: center
        justify-content: center
        svg
            fill: $white
            width: 18px
            height: 18px

.modal
    position: fixed
    display: none
    align-items: center
    justify-content: center
    flex-direction: column
    overflow: hidden
    margin: 0
    padding: 0
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 99

    &.active
        display: flex

    .modal-container
        background-color: rgba(0,0,0,.1)
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0

    .modal-content
        // display: flex
        flex-direction: column
        max-height: 100%
        width: 100%
        position: relative
        background-color: $white
        border-radius: 5px

        .header
            display: flex
            align-items: center
            padding: 20px
            background-color: $gray6
            border-bottom: 1px solid $gray4
            border-top-left-radius: 5px
            border-top-right-radius: 5px

            h2
                margin: 0
                font-size: 30px

            .icon
                width: 24px
                height: 24px
                cursor: pointer

                &:hover
                    fill: $green

        .body
            padding: 20px
            overflow-x: hidden
            overflow-y: auto
            height: calc( 100% - 71px )




.uptime
    width: 100%

    .uptime-header
        display: flex
        align-items: center
        justify-content: space-between
        gap: 10px
        margin-bottom: 20px

        .uptime-title
            font-size: 20px
            font-weight: 500
            color: $gray0

        .uptime-subtitle
            font-size: 14px
            font-weight: 500
            color: $gray0

    .uptime-content
        display: flex
        align-items: center
        gap: 5px

        .uptime-box
            display: flex
            align-items: center
            border-radius: 5px
            height: 50px
            width: 15px

            &.green
                background: $green
            &.red
                background: $red
            &.yellow
                background: $yellow
            &.blue
                background: $blue
            &.grey
                background: $gray2

.tooltip
    position: relative
    display: inline-block

    .tooltip-text
        display: none
        // visibility: hidden
        height: 100%
        width: 120px
        background-color: $gray0
        color: $white
        text-align: center
        border-radius: 6px
        padding: 5px 0
        position: absolute
        z-index: 4
        bottom: 125%
        left: 50%
        margin-left: -60px
        // opacity: 0
        transition: opacity 0.3s
        // border: 1px solid $gray3

        &.top
            bottom: 125%
            left: 50%
            margin-left: -60px

        &.right
            bottom: 4%
            left: 125%
            margin-left: 0px

            &::after
                top: 25%
                left: -4%
                border-color: transparent $gray0 transparent transparent

        &.bottom
            top: 125%

            &::after
                top: -40%
                left: 50%
                border-color: transparent transparent $gray0 transparent

        &.left
            bottom: 4%
            left: -200%
            margin-right: 0px

            &::after
                top: 25%
                left: 104%
                border-color: transparent transparent transparent $gray0

        &.ml100
            margin-left: -100%

        &::after
            content: ""
            position: absolute
            top: 100%
            left: 50%
            margin-left: -5px
            border-width: 5px
            border-style: solid
            border-color: $gray0 transparent transparent transparent

    &:hover .tooltip-text
        display: block
        // visibility: visible
        // opacity: 1

.gs-content
    width: 100%
    font-family: $font2
    font-weight: 500
    display: flex
    flex-direction: column
    // overflow-x: hidden

    .gs-content-filter
        width: 100%
        display: flex
        align-items: center
        justify-content: end

    &.border
        border: 1px solid $gray3

.gs-row
    display: grid
    width: 100%
    justify-content: space-between

    &:first-child

    .gs-column
        width: 100%
        display: flex
        align-items: center
        text-align: center

        &:first-child

        &.off
            background-color: $gray4

        &.on
            background-color: $gray2
            color: $white

        &.ok
            background-color: $green
            color: $white

        &.warnig
            background-color: $yellow
            color: $white

        &.error
            background-color: $red
            color: $white

        &.cursor-pointer
            cursor: pointer

        &.text-left
            text-align: left

        &.text-right
            text-align: right

        &.text-justify
            text-align: justify

        .text
            width: 100%

        .gs-icon
            fill: $gray0
            width: 20px
            height: 20px
            display: flex
            // margin: 0 5px 0 0

            &:hover
                fill: $white

.container-auth
    min-height: 100vh
    background-color: $gray6
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.box-login
    box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 15%)
    border: 1px solid rgba(0,0,0,0.15)
    background: $white
    border-radius: 5px
    padding: 1.5rem 2rem
    width: 350px

    form
        display: flex
        flex-direction: column
    .row
        display: flex
        flex-direction: column !important
        margin-bottom: 20px
        &:last-of-type
            margin-bottom: 0

    .logo
        width: 180px
        margin: 0 0 20px 0
        display: block
    .login-label
        text-transform: uppercase
        font-size: 12px
        letter-spacing: 1px
        font-weight: bold
        margin-bottom: 5px

    .title

    .box-login-content


.static-sidebar
    // height: calc( 100vh - 72px )
    width: auto
    height: auto
    border: 1px solid rgba(0, 0, 0, 0.15)

    .static-sidebar-content
        width: 100%
        background-color: $white

        ul
            list-style: none

            li
                text-align: center
                border-bottom: 1px solid rgba(0, 0, 0, 0.15)

                &:last-child
                    border: none

                a, div
                    padding: 15px
                    display: flex
                    align-items: center
                    gap: 5px
                    font-weight: 500
                    color: $gray1

                &.no-link
                    padding: 15px 0 15px 15px
                    display: flex
                    align-items: center
                    gap: 5px
                    font-weight: 500
                    color: $gray1

                &.active
                    a
                        background-color: $gray2
                        color: $white

                &.danger
                    a
                        background-color: #ffeaea

                &:hover
                    a
                        background-color: $gray2
                        color: $white

.pre-loader-content
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center

    .box-loader
        height: 50px
        width: 50px

        .loader
            background: url(../assets/imgs/loading.png) no-repeat scroll 10px center #fff
            background-size: 40px auto
            height: 100%


        // .loader
        //     width: 50px
        //     height: 50px
        //     -webkit-transition: all ease
        //     -moz-transition: all ease
        //     -ms-transition: all ease
        //     animation-duration: 1s
        //     animation-name: loader
        //     animation-iteration-count: infinite

        // @keyframes loader
        //     0%
        //         -webkit-transform: rotate(0deg)
        //         -moz-transform: rotate(0deg)
        //         -ms-transform: rotate(0deg)
        //         transform: rotate(0deg)

        //     100%
        //         -webkit-transform: rotate(360deg)
        //         -moz-transform: rotate(360deg)
        //         -ms-transform: rotate(360deg)
        //         transform: rotate(360deg)
.disabled
    pointer-events: none
    opacity: 0.6

.checkbox
    --background: #{$green}
    --color: #{$white}

    display: flex
    flex-wrap: wrap

    &.is-checked
        label
            background-color: var(--background)
            color: var(--color)
            border: 1px solid var(--background)

    input
        display: none

        &:checked + label
            background-color: var(--background)
            color: var(--color)
            border: 1px solid var(--background)
    label
        border: 1px solid $gray1
        border-radius: 3px
        padding: 3px 6px
        cursor: pointer
        -moz-user-select: none
        -ms-user-select: none
        -khtml-user-select: none
        -webkit-user-select: none
        -webkit-touch-callout: none

.input-radio-label, .input-checkbox-label
    display: none
    
    &:checked + .line
        background-color: $green
        color: $white
        border: 1px solid $green
    
    &:checked + .no-line
        color: $green

.radio-label, .checkbox-label
    width: max-content
    display: flex
    flex-wrap: wrap
    border: 1px solid $gray1
    border-radius: 3px
    padding: 3px 6px
    cursor: pointer
    -moz-user-select: none
    -ms-user-select: none
    -khtml-user-select: none
    -webkit-user-select: none
    -webkit-touch-callout: none

    &.no-line
        border: none
        border-radius: 0
        padding: 0

.tabs-init
    .tab
        border-right: 1px solid #dbdbdb !important
        &:nth-last-child(-n +2)
            border-right: 0px !important

.tab-init
    border-right: 1px solid #dbdbdb !important
    &:nth-last-child(-n +2)
        border-right: 0px !important
.tab-np
    border-left: 0px !important
    border-right: 0px !important    

.tabs
    display: inline-flex
    flex-wrap: wrap
    width: 100%
    margin-bottom: 20px

    .tab
        display: flex
        align-items: center
        justify-content: center
        padding: 10px 10px
        cursor: pointer
        margin-right: 5px
        margin-bottom: 10px
        font-weight: 500
        color: $gray2
        
        border-bottom-color: transparent
        border-bottom-style: solid
        border-bottom-width: 2px


        &.active
            color: $green
            border-bottom-color: $green 
            border-bottom-style: solid
            border-bottom-width: 2px
    

        &:hover
            border-bottom-color: $green
            border-bottom-style: solid
            border-bottom-width: 2px
            .close
                display: flex
                max-width: 10px
                max-height: 10px
                background-color: #eeeff2
                border-radius: 10%
                padding: 2px
                margin-left: 3px
                color: #111111
                align-items: center
        .close
            display: none
               
    
    .tab-content
        width: 100%
        display: none

        &.active
            display: block

.content-spinner
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center

  .spinner
    animation: rotate 2s linear infinite
    
    & .path
      stroke-linecap: round
      animation: dash 1.5s ease-in-out infinite

  @keyframes rotate
    100%
      transform: rotate(360deg)

  @keyframes dash
    0%
      stroke-dasharray: 1, 150
      stroke-dashoffset: 0
    50%
      stroke-dasharray: 90, 150
      stroke-dashoffset: -35
    100%
      stroke-dasharray: 90, 150
      stroke-dashoffset: -124

.recharts-responsive-container
    *
        font-family: $font1

    .recharts-text
        &.recharts-cartesian-axis-tick-value
            font-size: 12px
            font-weight: 500
            color: $black
        

    .custom-tooltip
        background-color: $white
        border: 1px solid $gray3
        border-radius: 3px
        .label
            border-bottom: 1px solid $gray3
            padding: 7px 15px
            font-weight: 600
            font-size: 15px
        .value
            padding: 7px 15px
            font-size: 15px
            display: flex
            gap: 10px
            align-items: center
            small
                display: block
                width: 10px
                height: 10px
                border-radius: 50%
                content: ''
                background-color: $green
                border: 1px solid $green2
                
        // box-shadow: 0 0 px 1px rgba(0, 0, 0, 0.2)
    // .recharts-wrapper
    //     width: 100% !important
    //     .recharts-surface
    //         width: 100% !important

.require
    color: $green

.white-nowrap
    white-space: nowrap
    
.minWdh70
    min-width: 70px

.minWdh80
    min-width: 80px

.responsive-table
    overflow: auto
    max-height: 500px
    padding-right: 10px
    table
        width: 100%
        border-collapse: collapse
        tr
            display: grid
            gap: 15px
            td
                word-wrap: break-word
            th
                word-wrap: break-word
                text-align: left


.content-order
    display: flex
    gap: 8px
    align-items: center
    justify-content: left
    .order-arrow
        height: 100%
        display: flex
        flex-direction: column

        svg
            height: 10px
            width: 10px
            fill: #ddd

            &:last-child
                transform: rotate(180deg)

            &:hover, &.on
                fill: #999

.p1
    background-color: #fff

span
    .close
        &:hover
            display: flex
            max-width: 10px
            max-height: 10px
            background-color: #eeeff2
            border-radius: 10%
            padding: 2px
            margin-left: 3px
            color: #111111

.color-white
    color: $white
    &:hover
        color: $white

.color-green
    color: $green
    &:hover
        color: $green

.color-gray-2
    color: $gray2
    &:hover
        color: $gray2

.b40
    bottom: 40px
.r40
    right: 40px
.l40
    left: 40px
.t40
    top: 40px

.b20
    bottom: 20px
.r20
    right: 20px
.l20
    left: 20px
.t20
    top: 20px

.w-auto
    width: auto !important

.h-auto
    height: auto !important

.inline-block
    display: inline-block

.init > *
    border-right: 1px solid rgba(0,0,0,0.15)

    &:last-of-type
        border-right: none

.init-bottom > *
    border-bottom: 1px solid rgba(0,0,0,0.15)

    &:last-of-type
        border-bottom: none

.capitalize
    text-transform: capitalize

.logout-img
    width: 25px
    height: 25px
    border-radius: 50px
    background-color: $green
    span
        font-size: 10px
        color: #FFF
.logout-drop
    position: absolute
    width: calc(100% + 40px)
    top: 40px
    padding: 15px 5px
    display: flex
    align-items: center
    justify-content: center
    background-color: #fff
    border-top: 1px solid #ccc
.content-logout
    min-width: 130px
.arrow
    &.on
        transform: rotate(0deg)

.pointer-none
    pointer-events: none



.close-content-list
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 99

.z-index-99
    z-index: 99

.z-index-100
    z-index: 100

.content-autocomplete
    min-width: 200px
    // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2)
    position: relative

    input
        // padding: 8px 40px 8px 15px !important
        font-size: 14px !important

.content-list
    width: 100%
    position: absolute
    background-color: #fff
    border-bottom-right-radius: 3px
    border-bottom-left-radius: 3px
    max-height: 200px
    overflow-y: auto
    cursor: pointer

    .item
        padding: 10px 15px
        font-size: 14px
        border-bottom: 1px solid #ccc
        &:last-child
            border-bottom: none
        
        &:hover
            background-color: #eee

        span
            overflow-wrap: anywhere



[class*="columns-"]
    display: grid

.columns-12
    grid-template-columns: repeat(12, 1fr)
.columns-11
    grid-template-columns: repeat(11, 1fr)
.columns-10
    grid-template-columns: repeat(10, 1fr)
.columns-9
    grid-template-columns: repeat(9, 1fr)
.columns-8
    grid-template-columns: repeat(8, 1fr)
.columns-7
    grid-template-columns: repeat(7, 1fr)
.columns-6
    grid-template-columns: repeat(6, 1fr)
.columns-5
    grid-template-columns: repeat(5, 1fr)
.columns-4
    grid-template-columns: repeat(4, 1fr)
.columns-3
    grid-template-columns: repeat(3, 1fr)
.columns-2
    grid-template-columns: repeat(2, 1fr)
.columns-1
    grid-template-columns: repeat(1, 1fr)
  
.columns-xl-12
    @include xl()
        grid-template-columns: repeat(12, 1fr)
.columns-xl-11
    @include xl()
        grid-template-columns: repeat(11, 1fr)
.columns-xl-10
    @include xl()
        grid-template-columns: repeat(10, 1fr)
.columns-xl-9
    @include xl()
        grid-template-columns: repeat(9, 1fr)
.columns-xl-8
    @include xl()
        grid-template-columns: repeat(8, 1fr)
.columns-xl-7
    @include xl()
        grid-template-columns: repeat(7, 1fr)
.columns-xl-6
    @include xl()
        grid-template-columns: repeat(6, 1fr)
.columns-xl-5
    @include xl()
        grid-template-columns: repeat(5, 1fr)
.columns-xl-4
    @include xl()
        grid-template-columns: repeat(4, 1fr)
.columns-xl-3
    @include xl()
        grid-template-columns: repeat(3, 1fr)
.columns-xl-2
    @include xl()
        grid-template-columns: repeat(2, 1fr)
.columns-xl-1
    @include xl()
        grid-template-columns: repeat(1, 1fr)
  
.columns-lg-12
    @include lg()
        grid-template-columns: repeat(12, 1fr)
.columns-lg-11
    @include lg()
        grid-template-columns: repeat(11, 1fr)
.columns-lg-10
    @include lg()
        grid-template-columns: repeat(10, 1fr)
.columns-lg-9
    @include lg()
        grid-template-columns: repeat(9, 1fr)
.columns-lg-8
    @include lg()
        grid-template-columns: repeat(8, 1fr)
.columns-lg-7
    @include lg()
        grid-template-columns: repeat(7, 1fr)
.columns-lg-6
    @include lg()
        grid-template-columns: repeat(6, 1fr)
.columns-lg-5
    @include lg()
        grid-template-columns: repeat(5, 1fr)
.columns-lg-4
    @include lg()
        grid-template-columns: repeat(4, 1fr)
.columns-lg-3
    @include lg()
        grid-template-columns: repeat(3, 1fr)
.columns-lg-2
    @include lg()
        grid-template-columns: repeat(2, 1fr)
.columns-lg-1
    @include lg()
        grid-template-columns: repeat(1, 1fr)
  
.columns-md-12
    @include md()
        grid-template-columns: repeat(12, 1fr)
.columns-md-11
    @include md()
        grid-template-columns: repeat(11, 1fr)
.columns-md-10
    @include md()
        grid-template-columns: repeat(10, 1fr)
.columns-md-9
    @include md()
        grid-template-columns: repeat(9, 1fr)
.columns-md-8
    @include md()
        grid-template-columns: repeat(8, 1fr)
.columns-md-7
    @include md()
        grid-template-columns: repeat(7, 1fr)
.columns-md-6
    @include md()
        grid-template-columns: repeat(6, 1fr)
.columns-md-5
    @include md()
        grid-template-columns: repeat(5, 1fr)
.columns-md-4
    @include md()
        grid-template-columns: repeat(4, 1fr)
.columns-md-3
    @include md()
        grid-template-columns: repeat(3, 1fr)
.columns-md-2
    @include md()
        grid-template-columns: repeat(2, 1fr)
.columns-md-1
    @include md()
        grid-template-columns: repeat(1, 1fr)
  
.columns-sm-12
    @include sm()
        grid-template-columns: repeat(12, 1fr)
.columns-sm-11
    @include sm()
        grid-template-columns: repeat(11, 1fr)
.columns-sm-10
    @include sm()
        grid-template-columns: repeat(10, 1fr)
.columns-sm-9
    @include sm()
        grid-template-columns: repeat(9, 1fr)
.columns-sm-8
    @include sm()
        grid-template-columns: repeat(8, 1fr)
.columns-sm-7
    @include sm()
        grid-template-columns: repeat(7, 1fr)
.columns-sm-6
    @include sm()
        grid-template-columns: repeat(6, 1fr)
.columns-sm-5
    @include sm()
        grid-template-columns: repeat(5, 1fr)
.columns-sm-4
    @include sm()
        grid-template-columns: repeat(4, 1fr)
.columns-sm-3
    @include sm()
        grid-template-columns: repeat(3, 1fr)
.columns-sm-2
    @include sm()
        grid-template-columns: repeat(2, 1fr)
.columns-sm-1
    @include sm()
        grid-template-columns: repeat(1, 1fr)
  
.columns-xs-12
    @include xs()
        grid-template-columns: repeat(12, 1fr)
.columns-xs-11
    @include xs()
        grid-template-columns: repeat(11, 1fr)
.columns-xs-10
    @include xs()
        grid-template-columns: repeat(10, 1fr)
.columns-xs-9
    @include xs()
        grid-template-columns: repeat(9, 1fr)
.columns-xs-8
    @include xs()
        grid-template-columns: repeat(8, 1fr)
.columns-xs-7
    @include xs()
        grid-template-columns: repeat(7, 1fr)
.columns-xs-6
    @include xs()
        grid-template-columns: repeat(6, 1fr)
.columns-xs-5
    @include xs()
        grid-template-columns: repeat(5, 1fr)
.columns-xs-4
    @include xs()
        grid-template-columns: repeat(4, 1fr)
.columns-xs-3
    @include xs()
        grid-template-columns: repeat(3, 1fr)
.columns-xs-2
    @include xs()
        grid-template-columns: repeat(2, 1fr)
.columns-xs-1
    @include xs()
        grid-template-columns: repeat(1, 1fr)

// COL CHILDREN
.col-12
    grid-column: span 12
.col-11
    grid-column: span 11
.col-10
    grid-column: span 10
.col-9
    grid-column: span 9
.col-8
    grid-column: span 8
.col-7
    grid-column: span 7
.col-6
    grid-column: span 6
.col-5
    grid-column: span 5
.col-4
    grid-column: span 4
.col-3
    grid-column: span 3
.col-2
    grid-column: span 2
.col-1
    grid-column: span 1
  
.col-xl-12
    @include xl()
        grid-column: span 12
.col-xl-11
    @include xl()
        grid-column: span 11
.col-xl-10
    @include xl()
        grid-column: span 10
.col-xl-9
    @include xl()
        grid-column: span 9
.col-xl-8
    @include xl()
        grid-column: span 8
.col-xl-7
    @include xl()
        grid-column: span 7
.col-xl-6
    @include xl()
        grid-column: span 6
.col-xl-5
    @include xl()
        grid-column: span 5
.col-xl-4
    @include xl()
        grid-column: span 4
.col-xl-3
    @include xl()
        grid-column: span 3
.col-xl-2
    @include xl()
        grid-column: span 2
.col-xl-1
    @include xl()
        grid-column: span 1
  
.col-lg-12
    @include lg()
        grid-column: span 12
.col-lg-11
    @include lg()
        grid-column: span 11
.col-lg-10
    @include lg()
        grid-column: span 10
.col-lg-9
    @include lg()
        grid-column: span 9
.col-lg-8
    @include lg()
        grid-column: span 8
.col-lg-7
    @include lg()
        grid-column: span 7
.col-lg-6
    @include lg()
        grid-column: span 6
.col-lg-5
    @include lg()
        grid-column: span 5
.col-lg-4
    @include lg()
        grid-column: span 4
.col-lg-3
    @include lg()
        grid-column: span 3
.col-lg-2
    @include lg()
        grid-column: span 2
.col-lg-1
    @include lg()
        grid-column: span 1
  
.col-md-12
    @include md()
        grid-column: span 12
.col-md-11
    @include md()
        grid-column: span 11
.col-md-10
    @include md()
        grid-column: span 10
.col-md-9
    @include md()
        grid-column: span 9
.col-md-8
    @include md()
        grid-column: span 8
.col-md-7
    @include md()
        grid-column: span 7
.col-md-6
    @include md()
        grid-column: span 6
.col-md-5
    @include md()
        grid-column: span 5
.col-md-4
    @include md()
        grid-column: span 4
.col-md-3
    @include md()
        grid-column: span 3
.col-md-2
    @include md()
        grid-column: span 2
.col-md-1
    @include md()
        grid-column: span 1
  
.col-sm-12
    @include sm()
        grid-column: span 12
.col-sm-11
    @include sm()
        grid-column: span 11
.col-sm-10
    @include sm()
        grid-column: span 10
.col-sm-9
    @include sm()
        grid-column: span 9
.col-sm-8
    @include sm()
        grid-column: span 8
.col-sm-7
    @include sm()
        grid-column: span 7
.col-sm-6
    @include sm()
        grid-column: span 6
.col-sm-5
    @include sm()
        grid-column: span 5
.col-sm-4
    @include sm()
        grid-column: span 4
.col-sm-3
    @include sm()
        grid-column: span 3
.col-sm-2
    @include sm()
        grid-column: span 2
.col-sm-1
    @include sm()
        grid-column: span 1
  
.col-xs-12
    @include xs()
        grid-column: span 12
.col-xs-11
    @include xs()
        grid-column: span 11
.col-xs-10
    @include xs()
        grid-column: span 10
.col-xs-9
    @include xs()
        grid-column: span 9
.col-xs-8
    @include xs()
        grid-column: span 8
.col-xs-7
    @include xs()
        grid-column: span 7
.col-xs-6
    @include xs()
        grid-column: span 6
.col-xs-5
    @include xs()
        grid-column: span 5
.col-xs-4
    @include xs()
        grid-column: span 4
.col-xs-3
    @include xs()
        grid-column: span 3
.col-xs-2
    @include xs()
        grid-column: span 2
.col-xs-1
    @include xs()
        grid-column: span 1

// MARGIN Y MEDIA QUERY XL, LG, MD, SM, XS
.m0
    margin: 0 !important
.m0-xl
    @include xl()
        margin: 0 !important
.m0-lg
    @include lg()
        margin: 0 !important
.m0-md
    @include md()
        margin: 0 !important
.m0-sm
    @include sm()
        margin: 0 !important
.m0-xs
    @include xs()
        margin: 0 !important

.m5
    margin: 5px !important
.m5-xl
    @include xl()
        margin: 5px !important
.m5-lg
    @include lg()
        margin: 5px !important
.m5-md
    @include md()
        margin: 5px !important
.m5-sm
    @include sm()
        margin: 5px !important
.m5-xs
    @include xs()
        margin: 5px !important

.m10
    margin: 10px !important
.m10-xl
    @include xl()
        margin: 10px !important
.m10-lg
    @include lg()
        margin: 10px !important
.m10-md
    @include md()
        margin: 10px !important
.m10-sm
    @include sm()
        margin: 10px !important
.m10-xs
    @include xs()
        margin: 10px !important

.m15
    margin: 15px !important
.m15-xl
    @include xl()
        margin: 15px !important
.m15-lg
    @include lg()
        margin: 15px !important
.m15-md
    @include md()
        margin: 15px !important
.m15-sm
    @include sm()
        margin: 15px !important
.m15-xs
    @include xs()
        margin: 15px !important

.m20
    margin: 20px !important
.m20-xl
    @include xl()
        margin: 20px !important
.m20-lg
    @include lg()
        margin: 20px !important
.m20-md
    @include md()
        margin: 20px !important
.m20-sm
    @include sm()
        margin: 20px !important
.m20-xs
    @include xs()
        margin: 20px !important

.m25
    margin: 25px !important
.m25-xl
    @include xl()
        margin: 25px !important
.m25-lg
    @include lg()
        margin: 25px !important
.m25-md
    @include md()
        margin: 25px !important
.m25-sm
    @include sm()
        margin: 25px !important
.m25-xs
    @include xs()
        margin: 25px !important

.m30
    margin: 30px !important
.m30-xl
    @include xl()
        margin: 30px !important
.m30-lg
    @include lg()
        margin: 30px !important
.m30-md
    @include md()
        margin: 30px !important
.m30-sm
    @include sm()
        margin: 30px !important
.m30-xs
    @include xs()
        margin: 30px !important

.m40
    margin: 40px !important
.m40-xl
    @include xl()
        margin: 40px !important
.m40-lg
    @include lg()
        margin: 40px !important
.m40-md
    @include md()
        margin: 40px !important
.m40-sm
    @include sm()
        margin: 40px !important
.m40-xs
    @include xs()
        margin: 40px !important

// MARGIN LEFT Y MEDIA QUERY XL, LG, MD, SM, XS
.ml0
    margin-left: 0 !important
.ml0-xl
    @include xl()
        margin-left: 0 !important
.ml0-lg
    @include lg()
        margin-left: 0 !important
.ml0-md
    @include md()
        margin-left: 0 !important
.ml0-sm
    @include sm()
        margin-left: 0 !important
.ml0-xs
    @include xs()
        margin-left: 0 !important

.ml5
    margin-left: 5px !important
.ml5-xl
    @include xl()
        margin-left: 5px !important
.ml5-lg
    @include lg()
        margin-left: 5px !important
.ml5-md
    @include md()
        margin-left: 5px !important
.ml5-sm
    @include sm()
        margin-left: 5px !important
.ml5-xs
    @include xs()
        margin-left: 5px !important

.ml10
    margin-left: 10px !important
.ml10-xl
    @include xl()
        margin-left: 10px !important
.ml10-lg
    @include lg()
        margin-left: 10px !important
.ml10-md
    @include md()
        margin-left: 10px !important
.ml10-sm
    @include sm()
        margin-left: 10px !important
.ml10-xs
    @include xs()
        margin-left: 10px !important

.ml15
    margin-left: 15px !important
.ml15-xl
    @include xl()
        margin-left: 15px !important
.ml15-lg
    @include lg()
        margin-left: 15px !important
.ml15-md
    @include md()
        margin-left: 15px !important
.ml15-sm
    @include sm()
        margin-left: 15px !important
.ml15-xs
    @include xs()
        margin-left: 15px !important

.ml20
    margin-left: 20px !important
.ml20-xl
    @include xl()
        margin-left: 20px !important
.ml20-lg
    @include lg()
        margin-left: 20px !important
.ml20-md
    @include md()
        margin-left: 20px !important
.ml20-sm
    @include sm()
        margin-left: 20px !important
.ml20-xs
    @include xs()
        margin-left: 20px !important

.ml25
    margin-left: 25px !important
.ml25-xl
    @include xl()
        margin-left: 25px !important
.ml25-lg
    @include lg()
        margin-left: 25px !important
.ml25-md
    @include md()
        margin-left: 25px !important
.ml25-sm
    @include sm()
        margin-left: 25px !important
.ml25-xs
    @include xs()
        margin-left: 25px !important

.ml30
    margin-left: 30px !important
.ml30-xl
    @include xl()
        margin-left: 30px !important
.ml30-lg
    @include lg()
        margin-left: 30px !important
.ml30-md
    @include md()
        margin-left: 30px !important
.ml30-sm
    @include sm()
        margin-left: 30px !important
.ml30-xs
    @include xs()
        margin-left: 30px !important

.ml40
    margin-left: 40px !important
.ml40-xl
    @include xl()
        margin-left: 40px !important
.ml40-lg
    @include lg()
        margin-left: 40px !important
.ml40-md
    @include md()
        margin-left: 40px !important
.ml40-sm
    @include sm()
        margin-left: 40px !important
.ml40-xs
    @include xs()
        margin-left: 40px !important

// MARGIN RIGHT Y MEDIA QUERY XL, LG, MD, SM, XS
.mr0
    margin-right: 0 !important
.mr0-xl
    @include xl()
        margin-right: 0 !important
.mr0-lg
    @include lg()
        margin-right: 0 !important
.mr0-md
    @include md()
        margin-right: 0 !important
.mr0-sm
    @include sm()
        margin-right: 0 !important
.mr0-xs
    @include xs()
        margin-right: 0 !important

.mr5
    margin-right: 5px !important
.mr5-xl
    @include xl()
        margin-right: 5px !important
.mr5-lg
    @include lg()
        margin-right: 5px !important
.mr5-md
    @include md()
        margin-right: 5px !important
.mr5-sm
    @include sm()
        margin-right: 5px !important
.mr5-xs
    @include xs()
        margin-right: 5px !important

.mr10
    margin-right: 10px !important
.mr10-xl
    @include xl()
        margin-right: 10px !important
.mr10-lg
    @include lg()
        margin-right: 10px !important
.mr10-md
    @include md()
        margin-right: 10px !important
.mr10-sm
    @include sm()
        margin-right: 10px !important
.mr10-xs
    @include xs()
        margin-right: 10px !important

.mr15
    margin-right: 15px !important
.mr15-xl
    @include xl()
        margin-right: 15px !important
.mr15-lg
    @include lg()
        margin-right: 15px !important
.mr15-md
    @include md()
        margin-right: 15px !important
.mr15-sm
    @include sm()
        margin-right: 15px !important
.mr15-xs
    @include xs()
        margin-right: 15px !important

.mr20
    margin-right: 20px !important
.mr20-xl
    @include xl()
        margin-right: 20px !important
.mr20-lg
    @include lg()
        margin-right: 20px !important
.mr20-md
    @include md()
        margin-right: 20px !important
.mr20-sm
    @include sm()
        margin-right: 20px !important
.mr20-xs
    @include xs()
        margin-right: 20px !important

.mr25
    margin-right: 25px !important
.mr25-xl
    @include xl()
        margin-right: 25px !important
.mr25-lg
    @include lg()
        margin-right: 25px !important
.mr25-md
    @include md()
        margin-right: 25px !important
.mr25-sm
    @include sm()
        margin-right: 25px !important
.mr25-xs
    @include xs()
        margin-right: 25px !important

.mr30
    margin-right: 30px !important
.mr30-xl
    @include xl()
        margin-right: 30px !important
.mr30-lg
    @include lg()
        margin-right: 30px !important
.mr30-md
    @include md()
        margin-right: 30px !important
.mr30-sm
    @include sm()
        margin-right: 30px !important
.mr30-xs
    @include xs()
        margin-right: 30px !important

.mr40
    margin-right: 40px !important
.mr40-xl
    @include xl()
        margin-right: 40px !important
.mr40-lg
    @include lg()
        margin-right: 40px !important
.mr40-md
    @include md()
        margin-right: 40px !important
.mr40-sm
    @include sm()
        margin-right: 40px !important
.mr40-xs
    @include xs()
        margin-right: 40px !important

// MARGIN TOP Y MEDIA QUERY XL, LG, MD, SM, XS
.mt0
    margin-top: 0 !important
.mt0-xl
    @include xl()
        margin-top: 0 !important
.mt0-lg
    @include lg()
        margin-top: 0 !important
.mt0-md
    @include md()
        margin-top: 0 !important
.mt0-sm
    @include sm()
        margin-top: 0 !important
.mt0-xs
    @include xs()
        margin-top: 0 !important

.mt5
    margin-top: 5px !important
.mt5-xl
    @include xl()
        margin-top: 5px !important
.mt5-lg
    @include lg()
        margin-top: 5px !important
.mt5-md
    @include md()
        margin-top: 5px !important
.mt5-sm
    @include sm()
        margin-top: 5px !important
.mt5-xs
    @include xs()
        margin-top: 5px !important

.mt10
    margin-top: 10px !important
.mt10-xl
    @include xl()
        margin-top: 10px !important
.mt10-lg
    @include lg()
        margin-top: 10px !important
.mt10-md
    @include md()
        margin-top: 10px !important
.mt10-sm
    @include sm()
        margin-top: 10px !important
.mt10-xs
    @include xs()
        margin-top: 10px !important

.mt15
    margin-top: 15px !important
.mt15-xl
    @include xl()
        margin-top: 15px !important
.mt15-lg
    @include lg()
        margin-top: 15px !important
.mt15-md
    @include md()
        margin-top: 15px !important
.mt15-sm
    @include sm()
        margin-top: 15px !important
.mt15-xs
    @include xs()
        margin-top: 15px !important

.mt20
    margin-top: 20px !important
.mt20-xl
    @include xl()
        margin-top: 20px !important
.mt20-lg
    @include lg()
        margin-top: 20px !important
.mt20-md
    @include md()
        margin-top: 20px !important
.mt20-sm
    @include sm()
        margin-top: 20px !important
.mt20-xs
    @include xs()
        margin-top: 20px !important

.mt25
    margin-top: 25px !important
.mt25-xl
    @include xl()
        margin-top: 25px !important
.mt25-lg
    @include lg()
        margin-top: 25px !important
.mt25-md
    @include md()
        margin-top: 25px !important
.mt25-sm
    @include sm()
        margin-top: 25px !important
.mt25-xs
    @include xs()
        margin-top: 25px !important

.mt30
    margin-top: 30px !important
.mt30-xl
    @include xl()
        margin-top: 30px !important
.mt30-lg
    @include lg()
        margin-top: 30px !important
.mt30-md
    @include md()
        margin-top: 30px !important
.mt30-sm
    @include sm()
        margin-top: 30px !important
.mt30-xs
    @include xs()
        margin-top: 30px !important

.mt40
    margin-top: 40px !important
.mt40-xl
    @include xl()
        margin-top: 40px !important
.mt40-lg
    @include lg()
        margin-top: 40px !important
.mt40-md
    @include md()
        margin-top: 40px !important
.mt40-sm
    @include sm()
        margin-top: 40px !important
.mt40-xs
    @include xs()
        margin-top: 40px !important

// MARGIN BOTTOM Y MEDIA QUERY XL, LG, MD, SM, XS
.mb0
    margin-bottom: 0 !important
.mb0-xl
    @include xl()
        margin-bottom: 0 !important
.mb0-lg
    @include lg()
        margin-bottom: 0 !important
.mb0-md
    @include md()
        margin-bottom: 0 !important
.mb0-sm
    @include sm()
        margin-bottom: 0 !important
.mb0-xs
    @include xs()
        margin-bottom: 0 !important

.mb5
    margin-bottom: 5px !important
.mb5-xl
    @include xl()
        margin-bottom: 5px !important
.mb5-lg
    @include lg()
        margin-bottom: 5px !important
.mb5-md
    @include md()
        margin-bottom: 5px !important
.mb5-sm
    @include sm()
        margin-bottom: 5px !important
.mb5-xs
    @include xs()
        margin-bottom: 5px !important

.mb10
    margin-bottom: 10px !important
.mb10-xl
    @include xl()
        margin-bottom: 10px !important
.mb10-lg
    @include lg()
        margin-bottom: 10px !important
.mb10-md
    @include md()
        margin-bottom: 10px !important
.mb10-sm
    @include sm()
        margin-bottom: 10px !important
.mb10-xs
    @include xs()
        margin-bottom: 10px !important

.mb15
    margin-bottom: 15px !important
.mb15-xl
    @include xl()
        margin-bottom: 15px !important
.mb15-lg
    @include lg()
        margin-bottom: 15px !important
.mb15-md
    @include md()
        margin-bottom: 15px !important
.mb15-sm
    @include sm()
        margin-bottom: 15px !important
.mb15-xs
    @include xs()
        margin-bottom: 15px !important

.mb20
    margin-bottom: 20px !important
.mb20-xl
    @include xl()
        margin-bottom: 20px !important
.mb20-lg
    @include lg()
        margin-bottom: 20px !important
.mb20-md
    @include md()
        margin-bottom: 20px !important
.mb20-sm
    @include sm()
        margin-bottom: 20px !important
.mb20-xs
    @include xs()
        margin-bottom: 20px !important

.mb25
    margin-bottom: 25px !important
.mb25-xl
    @include xl()
        margin-bottom: 25px !important
.mb25-lg
    @include lg()
        margin-bottom: 25px !important
.mb25-md
    @include md()
        margin-bottom: 25px !important
.mb25-sm
    @include sm()
        margin-bottom: 25px !important
.mb25-xs
    @include xs()
        margin-bottom: 25px !important

.mb30
    margin-bottom: 30px !important
.mb30-xl
    @include xl()
        margin-bottom: 30px !important
.mb30-lg
    @include lg()
        margin-bottom: 30px !important
.mb30-md
    @include md()
        margin-bottom: 30px !important
.mb30-sm
    @include sm()
        margin-bottom: 30px !important
.mb30-xs
    @include xs()
        margin-bottom: 30px !important

.mb40
    margin-bottom: 40px !important
.mb40-xl
    @include xl()
        margin-bottom: 40px !important
.mb40-lg
    @include lg()
        margin-bottom: 40px !important
.mb40-md
    @include md()
        margin-bottom: 40px !important
.mb40-sm
    @include sm()
        margin-bottom: 40px !important
.mb40-xs
    @include xs()
        margin-bottom: 40px !important


// PADDING Y MEDIA QUERY XL, LG, MD, SM, XS
.p0
    padding: 0 !important
.p0-xl
    @include xl()
        padding: 0 !important
.p0-lg
    @include lg()
        padding: 0 !important
.p0-md
    @include md()
        padding: 0 !important
.p0-sm
    @include sm()
        padding: 0 !important
.p0-xs
    @include xs()
        padding: 0 !important

.p5
    padding: 5px !important
.p5-xl
    @include xl()
        padding: 5px !important
.p5-lg
    @include lg()
        padding: 5px !important
.p5-md
    @include md()
        padding: 5px !important
.p5-sm
    @include sm()
        padding: 5px !important
.p5-xs
    @include xs()
        padding: 5px !important

.p10
    padding: 10px !important
.p10-xl
    @include xl()
        padding: 10px !important
.p10-lg
    @include lg()
        padding: 10px !important
.p10-md
    @include md()
        padding: 10px !important
.p10-sm
    @include sm()
        padding: 10px !important
.p10-xs
    @include xs()
        padding: 10px !important

.p15
    padding: 15px !important
.p15-xl
    @include xl()
        padding: 15px !important
.p15-lg
    @include lg()
        padding: 15px !important
.p15-md
    @include md()
        padding: 15px !important
.p15-sm
    @include sm()
        padding: 15px !important
.p15-xs
    @include xs()
        padding: 15px !important

.p20
    padding: 20px !important
.p20-xl
    @include xl()
        padding: 20px !important
.p20-lg
    @include lg()
        padding: 20px !important
.p20-md
    @include md()
        padding: 20px !important
.p20-sm
    @include sm()
        padding: 20px !important
.p20-xs
    @include xs()
        padding: 20px !important

.p25
    padding: 25px !important
.p25-xl
    @include xl()
        padding: 25px !important
.p25-lg
    @include lg()
        padding: 25px !important
.p25-md
    @include md()
        padding: 25px !important
.p25-sm
    @include sm()
        padding: 25px !important
.p25-xs
    @include xs()
        padding: 25px !important

.p30
    padding: 30px !important
.p30-xl
    @include xl()
        padding: 30px !important
.p30-lg
    @include lg()
        padding: 30px !important
.p30-md
    @include md()
        padding: 30px !important
.p30-sm
    @include sm()
        padding: 30px !important
.p30-xs
    @include xs()
        padding: 30px !important

.p40
    padding: 40px !important
.p40-xl
    @include xl()
        padding: 40px !important
.p40-lg
    @include lg()
        padding: 40px !important
.p40-md
    @include md()
        padding: 40px !important
.p40-sm
    @include sm()
        padding: 40px !important
.p40-xs
    @include xs()
        padding: 40px !important

// PADDING LEFT Y MEDIA QUERY XL, LG, MD, SM, XS
.pl0
    padding-left: 0 !important
.pl0-xl
    @include xl()
        padding-left: 0 !important
.pl0-lg
    @include lg()
        padding-left: 0 !important
.pl0-md
    @include md()
        padding-left: 0 !important
.pl0-sm
    @include sm()
        padding-left: 0 !important
.pl0-xs
    @include xs()
        padding-left: 0 !important

.pl5
    padding-left: 5px !important
.pl5-xl
    @include xl()
        padding-left: 5px !important
.pl5-lg
    @include lg()
        padding-left: 5px !important
.pl5-md
    @include md()
        padding-left: 5px !important
.pl5-sm
    @include sm()
        padding-left: 5px !important
.pl5-xs
    @include xs()
        padding-left: 5px !important

.pl10
    padding-left: 10px !important
.pl10-xl
    @include xl()
        padding-left: 10px !important
.pl10-lg
    @include lg()
        padding-left: 10px !important
.pl10-md
    @include md()
        padding-left: 10px !important
.pl10-sm
    @include sm()
        padding-left: 10px !important
.pl10-xs
    @include xs()
        padding-left: 10px !important

.pl15
    padding-left: 15px !important
.pl15-xl
    @include xl()
        padding-left: 15px !important
.pl15-lg
    @include lg()
        padding-left: 15px !important
.pl15-md
    @include md()
        padding-left: 15px !important
.pl15-sm
    @include sm()
        padding-left: 15px !important
.pl15-xs
    @include xs()
        padding-left: 15px !important

.pl20
    padding-left: 20px !important
.pl20-xl
    @include xl()
        padding-left: 20px !important
.pl20-lg
    @include lg()
        padding-left: 20px !important
.pl20-md
    @include md()
        padding-left: 20px !important
.pl20-sm
    @include sm()
        padding-left: 20px !important
.pl20-xs
    @include xs()
        padding-left: 20px !important

.pl25
    padding-left: 25px !important
.pl25-xl
    @include xl()
        padding-left: 25px !important
.pl25-lg
    @include lg()
        padding-left: 25px !important
.pl25-md
    @include md()
        padding-left: 25px !important
.pl25-sm
    @include sm()
        padding-left: 25px !important
.pl25-xs
    @include xs()
        padding-left: 25px !important

.pl30
    padding-left: 30px !important
.pl30-xl
    @include xl()
        padding-left: 30px !important
.pl30-lg
    @include lg()
        padding-left: 30px !important
.pl30-md
    @include md()
        padding-left: 30px !important
.pl30-sm
    @include sm()
        padding-left: 30px !important
.pl30-xs
    @include xs()
        padding-left: 30px !important

.pl40
    padding-left: 40px !important
.pl40-xl
    @include xl()
        padding-left: 40px !important
.pl40-lg
    @include lg()
        padding-left: 40px !important
.pl40-md
    @include md()
        padding-left: 40px !important
.pl40-sm
    @include sm()
        padding-left: 40px !important
.pl40-xs
    @include xs()
        padding-left: 40px !important

// PADDING RIGHT Y MEDIA QUERY XL, LG, MD, SM, XS
.pr0
    padding-right: 0 !important
.pr0-xl
    @include xl()
        padding-right: 0 !important
.pr0-lg
    @include lg()
        padding-right: 0 !important
.pr0-md
    @include md()
        padding-right: 0 !important
.pr0-sm
    @include sm()
        padding-right: 0 !important
.pr0-xs
    @include xs()
        padding-right: 0 !important

.pr5
    padding-right: 5px !important
.pr5-xl
    @include xl()
        padding-right: 5px !important
.pr5-lg
    @include lg()
        padding-right: 5px !important
.pr5-md
    @include md()
        padding-right: 5px !important
.pr5-sm
    @include sm()
        padding-right: 5px !important
.pr5-xs
    @include xs()
        padding-right: 5px !important

.pr10
    padding-right: 10px !important
.pr10-xl
    @include xl()
        padding-right: 10px !important
.pr10-lg
    @include lg()
        padding-right: 10px !important
.pr10-md
    @include md()
        padding-right: 10px !important
.pr10-sm
    @include sm()
        padding-right: 10px !important
.pr10-xs
    @include xs()
        padding-right: 10px !important

.pr15
    padding-right: 15px !important
.pr15-xl
    @include xl()
        padding-right: 15px !important
.pr15-lg
    @include lg()
        padding-right: 15px !important
.pr15-md
    @include md()
        padding-right: 15px !important
.pr15-sm
    @include sm()
        padding-right: 15px !important
.pr15-xs
    @include xs()
        padding-right: 15px !important

.pr20
    padding-right: 20px !important
.pr20-xl
    @include xl()
        padding-right: 20px !important
.pr20-lg
    @include lg()
        padding-right: 20px !important
.pr20-md
    @include md()
        padding-right: 20px !important
.pr20-sm
    @include sm()
        padding-right: 20px !important
.pr20-xs
    @include xs()
        padding-right: 20px !important

.pr25
    padding-right: 25px !important
.pr25-xl
    @include xl()
        padding-right: 25px !important
.pr25-lg
    @include lg()
        padding-right: 25px !important
.pr25-md
    @include md()
        padding-right: 25px !important
.pr25-sm
    @include sm()
        padding-right: 25px !important
.pr25-xs
    @include xs()
        padding-right: 25px !important

.pr30
    padding-right: 30px !important
.pr30-xl
    @include xl()
        padding-right: 30px !important
.pr30-lg
    @include lg()
        padding-right: 30px !important
.pr30-md
    @include md()
        padding-right: 30px !important
.pr30-sm
    @include sm()
        padding-right: 30px !important
.pr30-xs
    @include xs()
        padding-right: 30px !important

.pr40
    padding-right: 40px !important
.pr40-xl
    @include xl()
        padding-right: 40px !important
.pr40-lg
    @include lg()
        padding-right: 40px !important
.pr40-md
    @include md()
        padding-right: 40px !important
.pr40-sm
    @include sm()
        padding-right: 40px !important
.pr40-xs
    @include xs()
        padding-right: 40px !important

// PADDING TOP Y MEDIA QUERY XL, LG, MD, SM, XS
.pt0
    padding-top: 0 !important
.pt0-xl
    @include xl()
        padding-top: 0 !important
.pt0-lg
    @include lg()
        padding-top: 0 !important
.pt0-md
    @include md()
        padding-top: 0 !important
.pt0-sm
    @include sm()
        padding-top: 0 !important
.pt0-xs
    @include xs()
        padding-top: 0 !important

.pt5
    padding-top: 5px !important
.pt5-xl
    @include xl()
        padding-top: 5px !important
.pt5-lg
    @include lg()
        padding-top: 5px !important
.pt5-md
    @include md()
        padding-top: 5px !important
.pt5-sm
    @include sm()
        padding-top: 5px !important
.pt5-xs
    @include xs()
        padding-top: 5px !important

.pt10
    padding-top: 10px !important
.pt10-xl
    @include xl()
        padding-top: 10px !important
.pt10-lg
    @include lg()
        padding-top: 10px !important
.pt10-md
    @include md()
        padding-top: 10px !important
.pt10-sm
    @include sm()
        padding-top: 10px !important
.pt10-xs
    @include xs()
        padding-top: 10px !important

.pt15
    padding-top: 15px !important
.pt15-xl
    @include xl()
        padding-top: 15px !important
.pt15-lg
    @include lg()
        padding-top: 15px !important
.pt15-md
    @include md()
        padding-top: 15px !important
.pt15-sm
    @include sm()
        padding-top: 15px !important
.pt15-xs
    @include xs()
        padding-top: 15px !important

.pt20
    padding-top: 20px !important
.pt20-xl
    @include xl()
        padding-top: 20px !important
.pt20-lg
    @include lg()
        padding-top: 20px !important
.pt20-md
    @include md()
        padding-top: 20px !important
.pt20-sm
    @include sm()
        padding-top: 20px !important
.pt20-xs
    @include xs()
        padding-top: 20px !important

.pt25
    padding-top: 25px !important
.pt25-xl
    @include xl()
        padding-top: 25px !important
.pt25-lg
    @include lg()
        padding-top: 25px !important
.pt25-md
    @include md()
        padding-top: 25px !important
.pt25-sm
    @include sm()
        padding-top: 25px !important
.pt25-xs
    @include xs()
        padding-top: 25px !important

.pt30
    padding-top: 30px !important
.pt30-xl
    @include xl()
        padding-top: 30px !important
.pt30-lg
    @include lg()
        padding-top: 30px !important
.pt30-md
    @include md()
        padding-top: 30px !important
.pt30-sm
    @include sm()
        padding-top: 30px !important
.pt30-xs
    @include xs()
        padding-top: 30px !important

.pt40
    padding-top: 40px !important
.pt40-xl
    @include xl()
        padding-top: 40px !important
.pt40-lg
    @include lg()
        padding-top: 40px !important
.pt40-md
    @include md()
        padding-top: 40px !important
.pt40-sm
    @include sm()
        padding-top: 40px !important
.pt40-xs
    @include xs()
        padding-top: 40px !important

// PADDING BOTTOM Y MEDIA QUERY XL, LG, MD, SM, XS
.pb0
    padding-bottom: 0 !important
.pb0-xl
    @include xl()
        padding-bottom: 0 !important
.pb0-lg
    @include lg()
        padding-bottom: 0 !important
.pb0-md
    @include md()
        padding-bottom: 0 !important
.pb0-sm
    @include sm()
        padding-bottom: 0 !important
.pb0-xs
    @include xs()
        padding-bottom: 0 !important

.pb5
    padding-bottom: 5px !important
.pb5-xl
    @include xl()
        padding-bottom: 5px !important
.pb5-lg
    @include lg()
        padding-bottom: 5px !important
.pb5-md
    @include md()
        padding-bottom: 5px !important
.pb5-sm
    @include sm()
        padding-bottom: 5px !important
.pb5-xs
    @include xs()
        padding-bottom: 5px !important

.pb10
    padding-bottom: 10px !important
.pb10-xl
    @include xl()
        padding-bottom: 10px !important
.pb10-lg
    @include lg()
        padding-bottom: 10px !important
.pb10-md
    @include md()
        padding-bottom: 10px !important
.pb10-sm
    @include sm()
        padding-bottom: 10px !important
.pb10-xs
    @include xs()
        padding-bottom: 10px !important

.pb15
    padding-bottom: 15px !important
.pb15-xl
    @include xl()
        padding-bottom: 15px !important
.pb15-lg
    @include lg()
        padding-bottom: 15px !important
.pb15-md
    @include md()
        padding-bottom: 15px !important
.pb15-sm
    @include sm()
        padding-bottom: 15px !important
.pb15-xs
    @include xs()
        padding-bottom: 15px !important

.pb20
    padding-bottom: 20px !important
.pb20-xl
    @include xl()
        padding-bottom: 20px !important
.pb20-lg
    @include lg()
        padding-bottom: 20px !important
.pb20-md
    @include md()
        padding-bottom: 20px !important
.pb20-sm
    @include sm()
        padding-bottom: 20px !important
.pb20-xs
    @include xs()
        padding-bottom: 20px !important

.pb25
    padding-bottom: 25px !important
.pb25-xl
    @include xl()
        padding-bottom: 25px !important
.pb25-lg
    @include lg()
        padding-bottom: 25px !important
.pb25-md
    @include md()
        padding-bottom: 25px !important
.pb25-sm
    @include sm()
        padding-bottom: 25px !important
.pb25-xs
    @include xs()
        padding-bottom: 25px !important

.pb30
    padding-bottom: 30px !important
.pb30-xl
    @include xl()
        padding-bottom: 30px !important
.pb30-lg
    @include lg()
        padding-bottom: 30px !important
.pb30-md
    @include md()
        padding-bottom: 30px !important
.pb30-sm
    @include sm()
        padding-bottom: 30px !important
.pb30-xs
    @include xs()
        padding-bottom: 30px !important

.pb40
    padding-bottom: 40px !important
.pb40-xl
    @include xl()
        padding-bottom: 40px !important
.pb40-lg
    @include lg()
        padding-bottom: 40px !important
.pb40-md
    @include md()
        padding-bottom: 40px !important
.pb40-sm
    @include sm()
        padding-bottom: 40px !important
.pb40-xs
    @include xs()
        padding-bottom: 40px !important