@import 'utilities/variables'

.body
  font-family: "Gotham Narrow", sans-serif
  background: $white
  color: $gy
  width: 375px
  height: 550px

.content-middle
  width: 100%
  height: 100%
  background-color: $gy5
  overflow-x: hidden
  overflow-y: auto
  *
    font-family: "Gotham Narrow", sans-serif
    color: $gy
    margin: 0
    padding: 0
  .middle
    padding: 20px
    .cuestionario
      .content-title
        display: flex
        flex-direction: column
        gap: 20px
        margin-bottom: 20px
        .title
          font-size: 24px
          text-transform: uppercase
          font-weight: 600
          text-align: center
          line-height: 100%
        .question-state
          display: block
          font-size: 16px
          text-align: center
          span
            color: $green
            font-weight: 600
      .content-question
        display: flex
        flex-direction: column
        gap: 20px
        .title
          text-align: center
          display: block
          width: 100%
          font-size: 15px
          text-transform: uppercase
          font-weight: 600
          color: $gy6
          span
            font-size: 15px
            text-transform: uppercase
            font-weight: 600
            color: $gy9
        .content-group-col
          display: flex
          flex-direction: column
          gap: 10px
          .subtitle
            color: $gy9
            display: flex
            text-align: center
            flex-direction: column
            align-items: center
            justify-content: center
            text-transform: uppercase
            font-weight: 600
          .option
            .choice
              font-size: 15px
        .content-group-row
          display: flex
          align-items: center
          justify-content: space-between
          gap: 10px
          .subtitle
            width: 100%
            color: $gy9
            display: flex
            text-align: center
            flex-direction: column
            align-items: center
            justify-content: center
            text-transform: uppercase
            font-weight: 600
            font-size: 13px
            img
              display: block
              width: 60px
              height: 60px
              border: 2px solid $gy6
              border-radius: 60px
              background-color: $white
              object-fit: contain
          .option
            .choice
              font-size: 13px
        .group
          width: 100%
          display: flex
          justify-content: center
          flex-wrap: wrap
          gap: 10px
          .option
            position: relative
            input
              cursor: pointer
              position: absolute
              width: 100%
              height: 100%
              z-index: 1
              opacity: 0
              &:checked + label
                background: $green
                border: 1px solid $green
                color: $white
            .choice
              height: min-content
              padding: 10px
              display: flex
              text-align: center
              justify-content: center
              align-items: center
              background-color: $white
              border: 1px solid $gy
              color: $gy7
              font-weight: 600
              img
                display: block
                width: 45px
                height: 45px
              &.imagen
                min-width: 113px
                max-width: 113px
                min-height: 113px
                max-height: 113px
                flex-direction: column
                padding: 5px
                img
                  width: 60px
                  min-width: 60px
                  height: 60px
                  min-height: 60px
                  border: 2px solid #999999
                  border-radius: 60px
                  background-color: #fff
                  object-fit: contain
        .dnd
          .dnd-content
            display: flex
            flex-direction: column
            gap: 10px
            .dnd-content-item
              border: 2px solid $gy10
              border-radius: 3px
              width: 140px
              padding: 5px
              cursor: move
              user-select: none
              .dnd-item
                display: flex
                justify-content: space-between
                width: 100%
                align-items: center
                gap: 10px
                img
                  display: block
                  width: 45px
                  height: 45px
                  border: 2px solid $gy6
                  border-radius: 60px
                  background-color: $white
                  object-fit: contain
                span
                  color: $gy9
                  text-transform: uppercase
                  font-weight: 600
                  font-size: 13px
            .dnd-content-choice
              border: 2px solid $gy10
              border-radius: 3px
              width: 140px
              padding: 5px
              min-height: 58.2px
              cursor: move
              user-select: none
              color: $gy9
              text-transform: uppercase
              font-size: 13px
              font-weight: 600
              display: flex
              align-items: center
              justify-content: center
              gap: 10px
              flex-wrap: wrap
              .dnd-item
                display: flex
                justify-content: space-between
                width: 100%
                align-items: center
                gap: 10px
              img
                display: block
                width: 45px
                height: 45px
                border: 2px solid $gy6
                border-radius: 60px
                background-color: $white
                object-fit: contain
              span
                color: $gy9
                text-transform: uppercase
                font-weight: 600
                font-size: 13px
        .label-point
          text-align: center
          font-size: 20px
          text-transform: uppercase
          font-weight: 600
          color: $gy6
        .content-point
          display: flex
          align-items: center
          justify-content: space-between
          gap: 10px
          .point-item
            width: 150px
            padding: 5px
            user-select: none
            display: flex
            justify-content: flex-start
            align-items: center
            gap: 10px
            img
              display: block
              width: 45px
              min-width: 45px
              max-width: 45px
              height: 45px
              min-height: 45px
              max-height: 45px
              border: 2px solid $gy6
              border-radius: 60px
              background-color: $white
              object-fit: contain
            span
              color: $gy9
              text-transform: uppercase
              font-weight: 600
              font-size: 13px
          input
            border: 1px solid $gy10
            background-color: $white
            padding: 10px
            text-align: center
            width: 40px
            height: 40px
            outline: 0 none
          .point-option
            display: flex
            gap: 20px
            .plus
              background-color: $green
              min-width: 28px
              min-height: 28px
              max-width: 28px
              max-height: 28px
              display: flex
              justify-content: center
              align-items: center
              font-size: 24px
              color: $white
              cursor: pointer
            .less
              background-color: $red
              min-width: 28px
              min-height: 28px
              max-width: 28px
              max-height: 28px
              display: flex
              justify-content: center
              align-items: center
              font-size: 24px
              color: $white
              cursor: pointer
.content-extra
  width: 100%
  height: 60px
  min-height: 60px
  max-height: 60px
  .extra
    width: 100%
    height: 100%
    display: flex
    justify-content: space-between
    align-items: center
    padding: 10px 20px
    background-color: $green
    color: $white
    font-weight: 600
    font-size: 16px
    text-transform: uppercase
    border: none
    span
      color: $white
      font-weight: 600
      text-transform: uppercase
    .content-icon
      display: block
      width: 35px
      height: 35px
      flex-shrink: 0
      .icon
        display: block
        width: 100%
        height: 100%
        content: url(../assets/imgs/cupon.svg)
      .img-icon
        display: block
        width: 100%
        height: 100%
.content-footer
  width: 100%
  height: 75px
  min-height: 75px
  max-height: 75px
  .btn-submit
    text-align: left
    width: 100%
    height: 100%
    padding: 10px 20px
    background: url('../assets/imgs/right-arrow.svg') no-repeat scroll 93% center $gy8
    background-size: 35px
    color: $white
    font-weight: 600
    text-transform: uppercase
    border: none
    font-size: 14px
    &:active
      background-color: $green