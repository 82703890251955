//FONTS
@font-face
  font-family: 'Gotham Narrow'
  src: url('../fonts/GothamNarrow-Black.woff2') format('woff2'), url('../fonts/GothamNarrow-Black.woff') format('woff')
  font-weight: 900
  font-style: normal
  font-display: swap
@font-face
  font-family: 'Gotham Narrow'
  src: url('../fonts/GothamNarrow-Bold.woff2') format('woff2'), url('../fonts/GothamNarrow-Bold.woff') format('woff')
  font-weight: bold
  font-style: normal
@font-face
  font-family: 'Gotham Narrow'
  src: url('../fonts/GothamNarrow-Book.woff2') format('woff2'), url('../fonts/GothamNarrow-Book.woff') format('woff')
  font-weight: normal
  font-style: normal
@font-face
  font-family: 'Inter'
  src: url('../fonts/Inter-Black.woff2') format('woff2')
  font-weight: 900
  font-style: normal
@font-face
  font-family: 'Inter'
  src: url('../fonts/Inter-Light.woff2') format('woff2')
  font-weight: 300
  font-style: normal
@font-face
  font-family: 'Inter'
  src: url('../fonts/Inter-ExtraBold.woff2') format('woff2')
  font-weight: bold
  font-style: normal
@font-face
  font-family: 'Inter'
  src: url('../fonts/Inter-Medium.woff2') format('woff2')
  font-weight: 500
  font-style: normal
@font-face
  font-family: 'Inter'
  src: url('../fonts/Inter-Regular.woff2') format('woff2')
  font-weight: normal
  font-style: normal
@font-face
  font-family: 'Inter'
  src: url('../fonts/Inter-Bold.woff2') format('woff2')
  font-weight: bold
  font-style: normal
@font-face
  font-family: 'Inter'
  src: url('../fonts/Inter-SemiBold.woff2') format('woff2')
  font-weight: 600
  font-style: normal
@font-face
  font-family: 'Inter'
  src: url('../fonts/Inter-ExtraLight.woff2') format('woff2')
  font-weight: 200
  font-style: normal
@font-face
  font-family: 'Inter'
  src: url('../fonts/Inter-Thin.woff2') format('woff2')
  font-weight: 100
  font-style: normal

@mixin xl()
  @media only screen and (min-width: 1200px)
    @content

@mixin lg()
  @media only screen and (max-width: 1199px)
    @content

@mixin md()
  @media only screen and (max-width: 991px)
    @content

@mixin sm()
  @media only screen and (max-width: 767px)
    @content

@mixin xs()
  @media only screen and (max-width: 577px)
    @content

// @mixin xl()
//   @media only screen and (min-width: 1200px)
//     @content

// @mixin lg()
//   @media only screen and (min-width: 992px) and (max-width: 1199px)
//     @content

// @mixin md()
//   @media only screen and (min-width: 768px) and (max-width: 991px)
//     @content

// @mixin sm()
//   @media only screen and (min-width: 576px) and (max-width: 767px)
//     @content

// @mixin xs()
//   @media only screen and (max-width: 577px)
//     @content


//COLORS
$green: #71d44c
$green2: #53b130
$green3:rgb(76, 154, 48)
$black: #111
$black1: #363636
$gray0: #4A4A4A
$gray1: #606060
$gray2: #999999
$gray3: #ccc
$gray4: #eee
$gray5: #f1f1f1
$gray6: #f9fafb
$white: #fff
$orange: #ec7029
$orange2: #de590e
$blue: #2196F3
$blue2: rgb(0, 116, 199)
$red: #f44336
$yellow: #ffeb3b
$yellow2: rgb(251, 207, 35)
$purple: #9c27b0

$gy: #606060
$gy2: #EEEEEE
$gy3: #F1F1F1
$gy4: #BBBBBB
$gy5: #F9F9F9
$gy6: #999999
$gy7: #777777
$gy8: #666666
$gy9: #333333
$gy10: #CCCCCC
$red: #FF0000